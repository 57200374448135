<template>
  <v-btn outlined depressed color="primary" :to="{ path: '/score' }"
    >Check Your Score</v-btn
  >
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
